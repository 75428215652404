<template>
  <!--   :class="[type==2 || type==3 ? 'graybg': '']" -->
  <div class="commonMain" :class="diseClass">
    <el-input
      type="textarea"
      :rows="rows"
      resize="none"
      :placeholder="placeholderTxt"
      v-model="form.textarea"
    >
    </el-input>
    <!-- 上传图片   :file-list="fileList"  :auto-upload="false"  ="#" -->
    <div class="upbox" v-if="type == 5">
      <div class="flexbox imgboxisq" v-if="form.photos" >
        <div v-for="(item,index) in form.photos" :key="index">
          <span @click.stop="handleRemove(index)">
            <i class="el-icon-close"></i>
          </span>
          <img :src="item" alt=""  >
        </div>
        
      </div>
      <el-upload 
        action
        list-type="picture-card" 
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :http-request="(file) => {uploadSectionFile(file)}"
        >
        <i slot="default" class="el-icon-plus"></i>
         <!--  <div slot="file" slot-scope="{ file }">
          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview(file)"
            >
              <i class="el-icon-zoom-in"></i>
            </span>
            <span
              v-if="!disabled"
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
            >
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div> -->
      </el-upload>
      <el-dialog :visible.sync="dialogVisible" :modal="false">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </div>

    <div class="quesbox" :class="objClass">
      <div class="inputbox" v-if="type == 1 || type == 3 || type == 4">
        <el-select v-model="form.LawKnowledgeType" placeholder="请选择问题类型">
          <el-option
            v-for="item in allKnowledgelist"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>
      <div class="inputbox" v-if=" type == 2 || type == 3 || type == 4 ">
        <el-select v-model="form.fuwu" :disabled="Fuwudisabled" placeholder="请选择咨询方式" @change="fuwuChange" >
          <el-option
            v-for="item in Fuwulist"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>
      <div class="inputbox">
        <el-cascader
          v-model="Dataval"
          :options="CityInfo"
          :props="TagProps"
          placeholder="请选择所在地区"
          @change="handleChange"
        >
        </el-cascader>
      </div>
      <div class="inputbox">
        <el-input v-model="form.lxphone" placeholder="请输入联系方式"></el-input>
      </div>
      <div class="btnbox" @click.stop="tosubmit" v-if="type == 1 || type == 2 || type == 6">
        {{ subtext }}
      </div>
    </div>

    <!-- 提交咨询，提问页面显示 toask -->
    <div v-if="type == 3" class="toaskbox">
      <div class="bang">
        <div class="shouci">
          <span>当日首次免费</span>
        </div>
        <div class="btnbox type3" @click.stop="tosubmit">
          {{ subtext }}
        </div>
      </div>
    </div>

    <!-- 发布咨询， 法律咨询首页falvzixun（百底，多了一个咨询方式下拉框，发起咨询另起一行）-->
    <div v-if="type == 4" class="typebox4">
      <div class="btnbox" @click.stop="tosubmit">
        {{ subtext }}
      </div>
    </div>

    <!-- 发起咨询，服务首页falvfuwu（发起咨询另起一行）-->
    <div v-if="type == 5" class="typebox5">
      <div class="btnbox" @click.stop="tosubmit">
        {{ subtext }}
      </div>
    </div>

 
    <!-- 发布去付费时显示  -->
    <fabutip ref="fabutips" @toshow="toshow"></fabutip>
    <!-- 选择支付方式显示  -->
    <paytype ref="paytypetip" @tofree="tofree" @refresh="refresh"></paytype>
    <!-- 发布成功后显示  -->
    <successtip ref="fabusuccesstip"></successtip>

    <!-- 登录 -->
    <login ref="loginref"></login>
  </div>
</template>

<script>
import Dataoptions from '@/util/Data'
import fabutip from '@/components/tip/fabu'
import successtip from '@/components/tip/fabusuccess'
import paytype from '@/components/tip/paytype'
import login from '@/components/login'


import apiUrl from '@/api/main'
import apiUrlLogin from '@/api/login'
export default {
  name: 'index',
  components: { fabutip,successtip, paytype,login },
  props: {
    /**
     * 区分样式和部分显隐
     * 1 首页(基本样式，白底)
     * 2 热门领域详情页(灰底)
     * 3.发布咨询页面toask (灰底，多了一个咨询方式下拉框，发起咨询另起一行)
     * 4.法律咨询首页falvzixun（百底，多一个咨询方式下拉框，发起咨询另起一行）
     * 5. 服务首页falvfuwu（灰底，少一个请选择问题类型下拉框，发起咨询另起一行）
     * 
     */
    type: {
      type: Number,
      default: 1
    },
    wenshubiaoqian: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },

  },
  data() {
    return {
      orderid:'', // 订单id
      payinfo:{},
      fileList:[],
      // 灰底 底色
      diseClass: {
        graybg: this.type == 2 || this.type == 3 || this.type == 5
      },
      // 每个咨询的页面样式
      objClass: {
        flex_between: this.type == 1 || this.type == 2,
        flexboxtype3: this.type == 3 || this.type == 5,
        flexboxtype4: this.type == 4,
        flexboxtype6: this.type == 6,
      },
      rows:this.type == 6 ? 10 : 7,
   
      // 文本框的占位符
      placeholderTxt: '',
      // 首页
      placeholderTxt1: '请简述您要咨询的的法律问题',
      // 热门领域详情页
      placeholderTxt2:
        '请详细、完整描述您遇到的问题以及希望得到什么样的帮助（15字以上）',
      // 提问页面 toask.vue
      placeholderTxt3:
        '请描述下您要咨询的问题：1.问题发生的时间及背景；2.目前的情况；3.您的疑问或者诉求；',
      // 法律咨询首页 flaxindex.vue
      placeholderTxt4:
        '请描述您要咨询的问题： 1.问题发生的时间及背景；2.目前的情况现状；3.您的疑问或诉求;',
      // 服务首页 falvfuwu 
      placeholderTxt5: '请简述您的问题',
      // 问法首页
      placeholderTxt6: '请详细描述您遇到的法律问题',
      // 去咨询 显示文字
      subtext: '',
      subtext1: '立即提问', // 首页
      subtext2: '发起咨询', // 热门领域详情页
      subtext3: '发起咨询', // 提问页面 toask.vue
      subtext4: '发布咨询', // 法律咨询首页 flaxindex.vue
      subtext5: '发起咨询', // 服务首页 falvfuwu
      subtext6: '立即提问', // 问法首页 wenfa

      // options: [ ],
      allKnowledgelist:[], // 问题类型
      Fuwulist:[], // 咨询方式
      value: '',
      // 地区
      Dataval: [],
      CityInfo: Dataoptions,
     
      // 更改组件默认的数据键
      TagProps: {
        value: 'label',
        label: 'label',
        children: 'children'
      },
      input: '',
      // 上传图片
      dialogImageUrl: '',
      dialogVisible: false,
    
    
      form:{
        textarea:'', // 咨询内容
        LawKnowledgeType:'', // 问题类型
        region:'', // 所在地区
        lxphone:'', // 联系方式
        fuwu:'', // 咨询方式

        lawyerId:'', // 律师id
        provinceVal:'',
        cityVal:'',
        photos:[]
      },

      Fuwudisabled: false
    }
  },

  mounted() {
    if (this.type) {
      this.placeholderTxt = this[`placeholderTxt${this.type}`]
      this.subtext = this[`subtext${this.type}`]
    }

    this.getlsLawKnowledgelist(); // 问题类型
    this.getlsFuwulist(); // 咨询方式 服务

    // 首页直接进入提问toask页面，携带的数据
    if(this.$route.query.goback){
      let info =  JSON.parse(localStorage.getItem('askinfo'));
      this.form.textarea = info.textarea;
      this.form.lxphone = info.lxphone;
      this.form.LawKnowledgeType = info.LawKnowledgeType;
      this.form.region = info.provinceVal + info.cityVal;
      this.Dataval = [info.provinceVal,info.cityVal];
    }
    // 有律师id
    if(this.$route.query.lawid){
      this.form.lawyerId = this.$route.query.lawid;
    }else {
      this.form.lawyerId = '';
    }
    // 律师提供的服务
    if(this.$route.query.golvback){
      let lvinfo =  JSON.parse(localStorage.getItem('askinfolv'));
      this.payinfo.price = lvinfo.price;
      this.payinfo.type = lvinfo.type;
      this.form.fuwu = lvinfo.type;
    }

    // 文书代写
    if(window.location.hash.indexOf('/falvwenshu') > -1){
      let data = {}
      apiUrl.lsFuwulist(data).then((res) => {
          this.Fuwulist = res.rows;
          res.rows.forEach(element => {
          if(element.name == '文书代写'){
            this.payinfo.price = element.price;
            this.payinfo.type = element.name;
          }
        });
      })
    }

    // 法律咨询=》推荐服务 进入  咨询方式不可选
    if(this.$route.query.goflzixunback && this.$route.query.price && this.$route.query.type){
      this.payinfo.price =  this.$route.query.price;
      this.payinfo.type = this.$route.query.type;
      this.form.fuwu = this.$route.query.type;
      this.Fuwudisabled = true;
    }

    // type == 6 问法 免费咨询
    if(this.type == 6){
      this.payinfo.type = '免费咨询' // 咨询方式
      this.payinfo.price =  0  // 价格
    }

  

  
  },
  methods: {
    // 刷新清空
    refresh(){
      this.form = {}
      this.payinfo = {}
      this.Dataval = ''
      this.wenshubiaoqian = ''
      this.title = ''
    },
    // 选择地区
    handleChange(value) {
      // console.log(value)
      if (value[0] == '全国') {
        this.form.region = '';
        this.form.provinceVal = value[0];
      } else {
        this.form.region = value[0]+value[1];
        this.form.provinceVal = value[0];
        this.form.cityVal = value[1];
      }
     
    },
    handleAvatarSuccess(res, file) {
      this.form.photo = URL.createObjectURL(file.raw);
    },
    // 选择图片 param
    uploadSectionFile(param) {
      var fileObj = param.file;
      var from = new FormData();
      from.append("file", fileObj);
      // console.log(from,'===============',param)
      apiUrlLogin.upload(from).then((res) => {
        if (res.code == 0) {
          this.$message.success("上传成功");
         
          this.$nextTick(()=>{
            this.form.photos.push(res.url)
          })
         
        }
      });

    },

    handleRemove(index) {
      this.$nextTick(()=>{
        this.form.photos.splice(index, 1);
      });

    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 选择咨询方式
    fuwuChange(e){
      this.Fuwulist.forEach(element => {
        if(element.name == e){
          this.payinfo.price = element.price;
          this.payinfo.type = element.name;
        }
      });
    
    },
    // 发起咨询
    tosubmit() {
      // 登录
      if(!localStorage.getItem('userId')){
        this.$refs.loginref.open();
        return;
      }
    
      // 首页发起咨询直接跳到提问页面
      if(this.type == 1){
        this.$router.push({
          path: '/toask?goback=true'
        });

        localStorage.setItem('askinfo', JSON.stringify(this.form));
        return;
        
      } else {
        if(!this.form.textarea){
          this.$message({
            message:'请填写',
            type:'error'
          })
          return;
        }

        // 文书代写
        if(this.type == 5){
          if(this.form.photos.length <= 0){
            this.$message({
              message:'请上传',
              type:'error'
            })
            return;
          }
        }
        
        if(this.type == 3 || this.type == 4){
          if(!this.form.LawKnowledgeType){
            this.$message({
              message:'请选择问题类型',
              type:'error'
            })
            return;
          }
        }
        // 咨询方式
        if(!this.payinfo.type && !this.form.fuwu){
          this.$message({
            message:'请选择咨询方式',
            type:'error'
          })
          return;
        }


        // if(!this.form.region){
        //   this.$message({
        //     message:'请选择所在地区',
        //     type:'error'
        //   })
        //   return;
        // }

        if(!this.form.lxphone){
          this.$message({
            message:'请输入联系方式',
            type:'error'
          })
          return;
        }
        if(!/^1[3456789]{1}\d{9}$/gi.test(this.form.lxphone)){
          this.$message({
            message:'请检查联系方式',
            type:'error'
          })
            return false;
        }
      }
      this.tozixun();
    },

    // 去咨询
    tozixun(){
      let data = {
        userId: localStorage.getItem('userId'),
        fenglei: (this.type == 6 || this.type == 2) ? this.title : this.form.LawKnowledgeType, // 问题类型
        type: this.payinfo.type, // 咨询方式
        price: this.payinfo.price,  // 价格
        content: this.form.textarea, // 内容
        yuejianaddr: this.form.region,// 地点
        yuejianphone:this.form.lxphone, // 电话
      }
      //指派订单 律师id
      if(this.$route.query.lawid){
        data['lawyerId'] =  this.$route.query.lawid; 
      }
      // 有图片 文书代写
      if(this.form.photos.length >0){
        data['photos'] = this.form.photos.join(',');
      }
      if(this.type == 5){
        data['wenshubiaoqian'] = this.wenshubiaoqian;
      }

      
      apiUrl.addorder(data).then((res) => {
         if(res.code == 0){
          if(this.payinfo.type != '免费咨询'){
            // 选择支付方式
            this.$refs.paytypetip.open(this.payinfo, res.data.id);
          } else {
            // this.$message.success(res.msg);
          
            // 咨询成功后显示
            this.$refs.fabusuccesstip.open('gofalse');
         
          }

          // 订单id
          this.orderid = res.data.id;

         } else if(res.code == 500){
          localStorage.setItem('submitData', JSON.stringify(data));
          // 您当日的免费咨询次数已使用完毕，请选择付费咨询！
          this.$refs.fabutips.open();
         }
      });
    },

    // 去付费回调
    toshow(data){
      this.payinfo.type = data.type;
      this.payinfo.price = data.price;
      // 选择支付方式
      this.$refs.paytypetip.open(this.payinfo);
    },

    // 免费发起 回调
    tofree(){
      this.payinfo.type = '免费咨询';
      this.payinfo.price = 0;
    },

    // 获取所有问题类型
    getlsLawKnowledgelist() {
      let data = {
				pId: 0,
        userId: localStorage.getItem('userId') || ''
      }
      apiUrl.lsLawKnowledgelist(data).then((res) => {
          this.allKnowledgelist = res.rows;
      })
    },

    // 获取 精选服务 咨询方式
    getlsFuwulist() {
      let data = {
				// fuwutype: "精选服务"
      }
      apiUrl.lsFuwulist(data).then((res) => {
          this.Fuwulist = res.rows;

            // type == 4 法律咨询 首页 咨询方式默认为免费 服务列表 id=1 是免费咨询写死的
            if(this.type == 4){
              this.Fuwulist.forEach(element => {
                if(element.id ==1){
                  this.form.fuwu = element.name;
                  this.payinfo.type = '免费咨询' // 咨询方式
                  this.payinfo.price =  0  // 价格
                }
              });
            }
      })
    },
   
  }
}
</script>

<style lang="scss">
// 上传图片
.upbox{
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.imgboxisq{
  flex-wrap: wrap;
  div{
    position: relative;
  }
  span{
    position: absolute;
    top: -10px;
    right: 2px;
    font-size: 20px;
    cursor: pointer;
  }
  img{
    width:148px;
    height: 148px;
    margin-right:10px;
    margin-bottom:10px;
    border-radius: 6px;
  }
 
}
.quesbox {
  margin-top: 24px;
  // flex-wrap: wrap;
  .inputbox {
    width: 200px;
    height: 52px;
    background: #ffffff;
    border-radius: 10px;
    .el-input__inner{
      height: 52px;
      line-height: 52px;
    }
  }
  .btnbox {
    width: 168px;
    height: 52px;
    line-height: 52px;
    background: linear-gradient(270deg, #4298ff 0%, #305bfe 100%);
    border-radius: 10px;

    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}
// type ==3  提问首页toask
.toaskbox {
  margin: 0 auto;
  margin-top: 53px;
  position: relative;
  .bang {
    position: absolute;
    top: 0;
    left: 40%;
  }
  .shouci {
    background: url('../assets/img/shouci.png') no-repeat;
    background-size: 100% 100%;
    width: 120px;
    height: 42px;
    text-align: center;
    transform: translate(178px, 8px);
    span {
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      height: 42px;
      line-height: 30px;
    }
  }
}
.btnbox.type3 {
  width: 280px;
  height: 72px;
  line-height: 72px;
  border-radius: 36px;
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
}
.flexboxtype3 {
  display: flex;
  align-items: center;
  .inputbox {
    margin-right: 20px;
  }

}
// ============= type ==3   提问首页toask  end

// ============= type ==4   法律咨询首页flzxindex  start
.flexboxtype4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .inputbox {
    width: 245px;
    height: 56px;
    line-height: 56px;
    background: #ffffff;
    border-radius: 12px;
    .el-input__inner{
      width: 245px;
      height: 56px;
      line-height: 56px;
      border-radius: 12px;
    }
  }
  .inputbox:nth-child(1),
  .inputbox:nth-child(2) {
    margin-bottom: 16px;
  }

}
.typebox4 {
  width: 514px;
  height: 56px;
  line-height: 56px;
  background: linear-gradient(270deg, #4298ff 0%, #305bfe 100%);
  border-radius: 28px;
  margin-top: 40px;
  .btnbox{
    font-size: 20px;
    height: 56px;
    line-height: 56px;
  }
}
// ============= type ==4   法律咨询首页flzxindex  end

// type == 5 =================start
.typebox5 {
  margin: 0 auto;
  width: 280px;
  height: 72px;
  line-height: 72px;
  border-radius: 36px;
  margin-top: 40px;
  .btnbox{
    font-size: 24px;
    height: 72px;
    line-height: 72px;
    border-radius: 36px;
  }
}
// type == 5  ============end

// 问法首页  type == 6 start
.flexboxtype6{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .inputbox{
    width: 278px;
  }
  .btnbox{
    width: 184px;
    height: 52px;
    line-height: 52px;
    font-size: 16px;
  }
}
// 问法首页  type == 6 end

.el-cascader {
  width: 100% !important;
}
.el-cascader-panel {
  max-height: 300px !important;
}
.el-input__inner,
.el-textarea__inner {
  border: 1px solid transparent;
}

// type 为 2 时 样式
.graybg {
  .el-input__inner,
  .el-textarea__inner {
    background-color: #f5f5f5;
  }
}
</style>